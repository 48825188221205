import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Container,
  LinearProgress,
} from "@mui/material";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../components/DashboardLayout";
import Footer from "../../components/footer";
import ComplexStatisticsCard from "../../components/ComplexStatisticsCard";
import { getDashboardStats } from '../../services/api';

function Dashboard() {
  const [stats, setStats] = useState({
    total_clients: 0,
    total_users: 0,
    total_transactions: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        const data = await getDashboardStats();
        setStats(data);
      } catch (error) {
        console.error("Failed to fetch dashboard stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  return (
    <DashboardLayout>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Card elevation={3}>
          <CardContent>
            <Typography variant="h4" component="h2" gutterBottom align="center" color="primary">
              Dashboard Overview
            </Typography>
            {loading ? (
              <Box sx={{ width: '100%', my: 4 }}>
                <LinearProgress />
              </Box>
            ) : (
              <MDBox py={3}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} sm={6} md={4}>
                    <ComplexStatisticsCard
                      color="primary"
                      icon="group"
                      title="Total Clients"
                      count={stats.total_clients}
                      percentage={{
                        color: "success",
                        amount: "+5%",
                        label: "than last week",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ComplexStatisticsCard
                      color="info"
                      icon="person"
                      title="Total Users"
                      count={stats.total_users}
                      percentage={{
                        color: "success",
                        amount: "+3%",
                        label: "than last month",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="paid"
                      title="Total Transactions"
                      count={stats.total_transactions}
                      percentage={{
                        color: "success",
                        amount: "+1%",
                        label: "than yesterday",
                      }}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            )}
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;