import { useState, useEffect } from "react";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../components/DashboardLayout";
import Footer from "../../components/footer";
import ClientTable from "../../components/Tables/ClientTable";
import { getClients } from "../../services/api";

function Clients() {
  const [clientsData, setClientsData] = useState({ clients: [], total: 0 });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchClients();
  }, [page, rowsPerPage]);

  const fetchClients = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await getClients(page + 1, rowsPerPage);
      setClientsData(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Failed to fetch clients');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <ClientTable
          clients={clientsData.clients}
          total={clientsData.total}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          isLoading={isLoading}
          error={error}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Clients;