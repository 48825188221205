import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#44515b',  // A cool teal shade
      contrastText: '#ffffff', // Ensures good readability on primary elements
    },
    secondary: {
      main: '#8e24aa',  // A rich purple for accentuation
      contrastText: '#ffffff', // Keeps the text clear on secondary elements
    },
    background: {
      default: '#ffffff',  // Light grey for the overall background
      paper: '#ffffff',  // White background for cards, tables, etc.
    },
    text: {
      primary: '#212121',  // Dark grey for primary text
      secondary: '#757575',  // Lighter grey for secondary text
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',  // A modern, clean font combo
    h1: {
      fontWeight: 600,  // Bold headers
      fontSize: '2.5rem', // Slightly larger for visual hierarchy
    },
    h2: {
      fontWeight: 500,
      fontSize: '2rem',
    },
    body1: {
      fontWeight: 400,  // Regular text weight for body text
      fontSize: '1rem',  // Standard size for readable content
    },
    button: {
      textTransform: 'none',  // No uppercase transformation for buttons
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,  // Slightly rounded corners for UI elements
  },
  spacing: 8,  // Standardized spacing for padding, margins, etc.
});

export default theme;
