import axios from 'axios';

const API_BASE_URL = 'https://devpaymentsapi.tenup.io/api';

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Centralized logout function
const logoutUser = () => {
  localStorage.removeItem('adminToken');
  // Redirect to login page
  window.location.href = '/login';
};

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('adminToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Check if the token has expired
      if (error.response.data.msg === "Token has expired") {
        logoutUser();
      }
    }
    return Promise.reject(error);
  }
);

const handleApiError = (error) => {
  console.error('API Error:', error);
  if (error.response) {
    throw new Error(error.response.data.error || error.response.data.msg || 'An error occurred');
  } else if (error.request) {
    throw new Error('No response received from the server');
  } else {
    throw new Error('Error setting up the request');
  }
};

export const login = async (username, password) => {
  try {
    const response = await api.post('/admin/login', { username, password });
    localStorage.setItem('adminToken', response.data.access_token);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const logout = () => {
  logoutUser();
};

export const getClients = async (page = 1, perPage = 10) => {
  try {
    const response = await api.get('/admin/clients', { params: { page, per_page: perPage } });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getUsersByClient = async (clientId, page = 1, perPage = 10) => {
  try {
    const response = await api.get(`/admin/clients/${clientId}/users`, { params: { page, per_page: perPage } });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getTransactions = async (page = 1, perPage = 10) => {
  try {
    const response = await api.get('/admin/transactions', { params: { page, per_page: perPage } });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getUserTransactions = async (userId, page = 1, perPage = 10) => {
  try {
    const response = await api.get(`/admin/users/${userId}/transactions`, { params: { page, per_page: perPage } });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getDashboardStats = async () => {
  try {
    const response = await api.get('/admin/dashboard-stats');
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const resendCallback = async (transactionId) => {
  try {
    const response = await api.post(`/admin/transactions/${transactionId}/resend-callback`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getWithdrawals = async (page = 1, perPage = 10) => {
  try {
    const response = await api.get('/admin/withdrawals', { params: { page, per_page: perPage } });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const approveWithdrawal = async (withdrawalId) => {
  try {
    const response = await api.post(`/admin/approve-withdrawal/${withdrawalId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { message: "Withdrawal already approved or not found" };
    }
    throw error;
  }
};
export default api;