import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  IconButton,
  Box,
  InputAdornment,
  Tooltip,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import MDTypography from "../MDTypography";
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import ReplayIcon from '@mui/icons-material/Replay';
import { styled } from '@mui/material/styles';
import { resendCallback } from "../../services/api";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  borderBottom: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function TransactionTable({ transactions, total, page, rowsPerPage, onPageChange, onRowsPerPageChange, isLoading, error }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [errorDialog, setErrorDialog] = useState({ open: false, title: '', message: '' });

  const handleResendCallback = async (transactionId) => {
    try {
      await resendCallback(transactionId);
      setSnackbar({ open: true, message: 'Callback resent successfully', severity: 'success' });
    } catch (error) {
      let errorMessage = 'Failed to resend callback';
      let errorDetails = '';
      if (error.response && error.response.data) {
        errorMessage = error.response.data.error || errorMessage;
        errorDetails = error.response.data.details || '';
        if (error.response.data.callback_url) {
          errorDetails += `\nCallback URL: ${error.response.data.callback_url}`;
        }
      }
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
      if (errorDetails) {
        setErrorDialog({ open: true, title: 'Error Details', message: errorDetails });
      }
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCloseErrorDialog = () => {
    setErrorDialog({ ...errorDialog, open: false });
  };

  const filteredTransactions = useMemo(() => {
    return transactions.filter(
      (transaction) =>
        transaction.channel.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [transactions, searchTerm]);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 2, overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
        <TextField
          placeholder="Search Client"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: 300 }}
        />
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="transaction table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Transaction ID
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  User ID
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Amount
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Currency
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Channel
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Timestamp
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Actions
                </MDTypography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">Loading...</TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={7} align="center" sx={{ color: 'error.main' }}>{error}</TableCell>
              </TableRow>
            ) : (
              filteredTransactions.map((transaction) => (
                <StyledTableRow key={transaction.id}>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {transaction.paymentId}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {transaction.userId}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {transaction.pay_amount}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {transaction.pay_currency}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {transaction.channel}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {new Date(transaction.timestamp).toLocaleString()}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Transaction Details">
                      <IconButton size="small">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Resend Callback">
                      <IconButton size="small" onClick={() => handleResendCallback(transaction.id)}>
                        <ReplayIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog open={errorDialog.open} onClose={handleCloseErrorDialog}>
        <DialogTitle>{errorDialog.title}</DialogTitle>
        <DialogContent>
          <pre>{errorDialog.message}</pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

TransactionTable.propTypes = {
  transactions: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default TransactionTable;