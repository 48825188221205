import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import theme from '../../../theme/theme';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: theme.palette.primary.main, // Dark blue
    },
    background: {
      default: theme.palette.action.hover,
      paper: '#1e1e1e',
    },
  },
});

const Navbar = ({ onLogout }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="static" color="primary" enableColorOnDark elevation={0}>
        <Toolbar sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.12)' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontWeight: 'bold', color: '#fff' }}
          >
            Admin Dashboard
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {['Dashboard', 'Clients', 'Users','Withdrawals'].map((text) => (
              <Button
                key={text}
                color="inherit"
                component={RouterLink}
                to={`/${text.toLowerCase()}`}
                sx={{
                  color: 'rgba(255, 255, 255, 0.7)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.08)',
                    color: '#fff'
                  }
                }}
              >
                {text}
              </Button>
            ))}
            <Button
              color="inherit"
              onClick={onLogout}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                color: '#fff',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.16)',
                }
              }}
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Navbar;