import { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../components/DashboardLayout";
import Footer from "../../components/footer";
import UserTable from "../../components/Tables/UserTable";
import { getClients, getUsersByClient } from "../../services/api";

function Users() {
  const [usersData, setUsersData] = useState({ users: [], total: 0 });
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    if (selectedClient || clients.length > 0) {
      fetchUsers();
    }
  }, [selectedClient, page, rowsPerPage, clients]);

  const fetchClients = async () => {
    try {
      const response = await getClients();
      setClients(response.clients);
      if (response.clients.length > 0) {
        setSelectedClient(response.clients[0].client_id);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Failed to fetch clients');
    }
  };

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const clientId = selectedClient || (clients.length > 0 ? clients[0].client_id : '');
      if (clientId) {
        const response = await getUsersByClient(clientId, page + 1, rowsPerPage);
        setUsersData(response);
      } else {
        setUsersData({ users: [], total: 0 });
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <MDTypography variant="h6" fontWeight="medium">
            Users
          </MDTypography>
        </MDBox>
        <Box display="flex" justifyContent="center" mb={3}>
          <FormControl sx={{ minWidth: 300, width: '50%' }}>
            <InputLabel>Select Client</InputLabel>
            <Select value={selectedClient} onChange={handleClientChange}>
              {clients.map((client) => (
                <MenuItem key={client.client_id} value={client.client_id}>
                  {client.client_id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <UserTable
          users={usersData.users}
          total={usersData.total}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          isLoading={isLoading}
          error={error}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Users;