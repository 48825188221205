import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Box,
  Button,
  Avatar,
  IconButton,
  InputAdornment,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Card from "@mui/material/Card";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  borderBottom: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function UserTable({ users, total, page, rowsPerPage, onPageChange, onRowsPerPageChange, isLoading, error }) {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const filteredUsers = useMemo(() => {
    return users.filter(
      (user) =>
        user?.user_id?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        user?.email?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [users, searchTerm]);

  const handleUserTransactions = (userId) => {
    if (userId) {
      navigate(`/users/${userId}/transactions`);
    } else {
      console.error("User ID is undefined");
    }
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 2, overflow: 'hidden' }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
      <TextField
        placeholder="Search Client"
        variant="outlined"
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: 300 }}
      />
     
    </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="user table">
            <TableHead>
              <TableRow>
                
                <StyledTableCell>
                  <MDTypography variant="caption" fontWeight="medium" color="text">
                    Email
                  </MDTypography>
                </StyledTableCell>
               
                <StyledTableCell>
                  <MDTypography variant="caption" fontWeight="medium" color="text">
                    Actions
                  </MDTypography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">Loading...</TableCell>
                </TableRow>
              ) : error ? (
                <TableRow>
                  <TableCell colSpan={4} align="center" sx={{ color: 'error.main' }}>{error}</TableCell>
                </TableRow>
              ) : (
                filteredUsers.map((user) => (
                  <StyledTableRow key={user.id}>
                   
                    <TableCell>
                      <MDTypography variant="caption" fontWeight="medium">
                        {user.email}
                      </MDTypography>
                    </TableCell>
                   
                    <TableCell>
                      <IconButton size="small" onClick={() => handleUserTransactions(user.id)}>
                        <ArrowForwardIosIcon fontSize="small" />
                      </IconButton>
                      
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Box>
  );
}

UserTable.propTypes = {
  users: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default UserTable;