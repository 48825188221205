import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const ComplexStatisticsCard = ({ title, count, icon, color }) => {
  return (
    <Card sx={{ bgcolor: color }}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="div">
          {count}
        </Typography>
        {icon}
      </CardContent>
    </Card>
  );
};

export default ComplexStatisticsCard;