import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box component="footer" sx={{ mt: 'auto', py: 2, textAlign: 'center' }}>
      <Typography variant="body2" color="text.secondary">
        © 2023 Your Company Name
      </Typography>
    </Box>
  );
};

export default Footer;