import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardNavbar from '../../components/Navbars/DashboardNavbar'
import Footer from "../../components/footer";
import TransactionTable from "../../components/Tables/TransactionTable";
import { getTransactions, getUserTransactions } from "../../services/api";
import { Button, TextField } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Transactions() {
  const [transactionsData, setTransactionsData] = useState({ transactions: [], total: 0 });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { userId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchTransactions();
  }, [page, rowsPerPage, userId]);

  const fetchTransactions = async () => {
    try {
      setIsLoading(true);
      setError(null);
      let response;
      if (userId) {
        response = await getUserTransactions(userId, page + 1, rowsPerPage);
      } else {
        response = await getTransactions(page + 1, rowsPerPage);
      }
      setTransactionsData(response);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setError('Failed to fetch transactions');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const filteredTransactions = transactionsData.transactions.filter(
    (transaction) =>
      transaction.id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      transaction.channel?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <MDBox mb={3} display="flex" alignItems="center">
          <Button startIcon={<ArrowBackIcon />} onClick={handleBack}>
            Back
          </Button>
          <MDTypography variant="h6" fontWeight="medium" ml={2}>
            {userId ? `User Transactions` : 'All Transactions'}
          </MDTypography>
        </MDBox>
       
        <TransactionTable
          transactions={filteredTransactions}
          total={transactionsData.total}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          isLoading={isLoading}
          error={error}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Transactions;