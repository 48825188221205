import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  IconButton,
  Box,
  InputAdornment,
  Tooltip,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import MDTypography from "../MDTypography";
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import { approveWithdrawal } from "../../services/api";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
  borderBottom: 'none',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function WithdrawalTable({ withdrawals, total, page, rowsPerPage, onPageChange, onRowsPerPageChange, isLoading, error, onApprove }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [errorDialog, setErrorDialog] = useState({ open: false, title: '', message: '' });
  const [processingWithdrawals, setProcessingWithdrawals] = useState(new Set());

  const handleApproveWithdrawal = async (withdrawalId) => {
    if (processingWithdrawals.has(withdrawalId)) {
      console.log('Withdrawal already being processed');
      return;
    }

    setProcessingWithdrawals(prev => new Set(prev).add(withdrawalId));

    try {
      const result = await approveWithdrawal(withdrawalId);
      setSnackbar({ open: true, message: result.message || 'Withdrawal approved successfully', severity: 'success' });
      onApprove(withdrawalId);
    } catch (error) {
      let errorMessage = 'Failed to approve withdrawal';
      let errorDetails = '';
      if (error.response && error.response.data) {
        errorMessage = error.response.data.error || errorMessage;
        errorDetails = error.response.data.details || '';
      }
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
      if (errorDetails) {
        setErrorDialog({ open: true, title: 'Error Details', message: errorDetails });
      }
    } finally {
      setProcessingWithdrawals(prev => {
        const newSet = new Set(prev);
        newSet.delete(withdrawalId);
        return newSet;
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleCloseErrorDialog = () => {
    setErrorDialog({ ...errorDialog, open: false });
  };

  const filteredWithdrawals = useMemo(() => {
    return withdrawals.filter(
      (withdrawal) =>
        withdrawal.user_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        withdrawal.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [withdrawals, searchTerm]);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 2, overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
        <TextField
          placeholder="Search User ID or Status"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: 300 }}
        />
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="withdrawal table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Withdrawal ID
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  User ID
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Amount
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Currency
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Status
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Created At
                </MDTypography>
              </StyledTableCell>
              <StyledTableCell>
                <MDTypography variant="caption" fontWeight="medium" color="text">
                  Actions
                </MDTypography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={7} align="center" sx={{ color: 'error.main' }}>{error}</TableCell>
              </TableRow>
            ) : filteredWithdrawals.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">No withdrawals found</TableCell>
              </TableRow>
            ) : (
              filteredWithdrawals.map((withdrawal) => (
                <StyledTableRow key={withdrawal.id}>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {withdrawal.id}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {withdrawal.user_id}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {withdrawal.amount}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {withdrawal.currency}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {withdrawal.status}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant="caption" fontWeight="medium">
                      {new Date(withdrawal.created_at).toLocaleString()}
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Withdrawal Details">
                      <IconButton size="small">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    {withdrawal.status === 'pending' && (
                      <Tooltip title={processingWithdrawals.has(withdrawal.id) ? "Processing..." : "Approve Withdrawal"}>
                        <span>
                          <IconButton
                            size="small"
                            onClick={() => handleApproveWithdrawal(withdrawal.id)}
                            disabled={processingWithdrawals.has(withdrawal.id)}
                          >
                            {processingWithdrawals.has(withdrawal.id) ? (
                              <CircularProgress size={20} />
                            ) : (
                              <CheckCircleIcon fontSize="small" />
                            )}
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog open={errorDialog.open} onClose={handleCloseErrorDialog}>
        <DialogTitle>{errorDialog.title}</DialogTitle>
        <DialogContent>
          <pre>{errorDialog.message}</pre>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

WithdrawalTable.propTypes = {
  withdrawals: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  onApprove: PropTypes.func.isRequired,
};

export default WithdrawalTable;