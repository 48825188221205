import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MDBox from "../../components/MDBox";
import Footer from "../../components/footer";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../components/DashboardLayout";
import DashboardNavbar from '../../components/Navbars/DashboardNavbar'
import WithdrawalTable from "../../components/Tables/WithdrawalTable";
import { getWithdrawals } from "../../services/api";

function Withdrawals() {
  const [withdrawalsData, setWithdrawalsData] = useState({ withdrawals: [], total: 0 });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchWithdrawals = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await getWithdrawals(page + 1, rowsPerPage);
      setWithdrawalsData(response);
    } catch (error) {
      console.error('Error fetching withdrawals:', error);
      setError('Failed to fetch withdrawals');
    } finally {
      setIsLoading(false);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchWithdrawals();
  }, [fetchWithdrawals]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApprove = useCallback((withdrawalId) => {
    setWithdrawalsData(prevData => ({
      ...prevData,
      withdrawals: prevData.withdrawals.map(w =>
        w.id === withdrawalId ? { ...w, status: 'approved' } : w
      )
    }));
  }, []);

  return (
    <DashboardLayout>
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <MDTypography variant="h6" fontWeight="medium">
            Withdrawals
          </MDTypography>
        </MDBox>
        <WithdrawalTable
          withdrawals={withdrawalsData.withdrawals}
          total={withdrawalsData.total}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          isLoading={isLoading}
          error={error}
          onApprove={handleApprove}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Withdrawals;